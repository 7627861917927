.textinthe h4{
    color: #2c5a9f;
}
.centerimg{
    display: flex;
    align-items: center;
    justify-content: center;
}
.serverssss{
    margin: 0px 0px;
    width:100%;
    padding:20px 20px;
    background-color: #0a1954;
    border-radius: 5px;
    display: block;
}
.backcolord{
    border: 2px solid #08263e;
    padding: 30px 10px;
}
.colorsblue{
    color: #0a1954;
}
.inputers{
    background-color: white;
    height: 35px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 3px;
}
.inputerss{
    background-color: white;
    height: 80px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 3px;
}
.submiter{
    padding: 8px 30px;
    border-radius: 3px;
    font-size: 22px;
    font-weight: 600;
    background-color: #61bbd1;
    border: 0px;
    color: white;
}
.secservice{
    background-image:linear-gradient(rgba(255, 255, 255, 0.745),rgba(255, 255, 255, 0.389)), url(../../../public/photo/ad-bg-ser.jpg);
    background-position: center;
    background-size: cover;
}
.subber{
    display: flex;
    justify-content: right;
}
.topperseers{
    background-image:linear-gradient(rgba(255, 255, 255, 0.281),rgba(255, 255, 255, 0.336)), url(../../../public/photo/ared.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}
.higerli{
    display: flex;
    align-items: center;
}
.hiders{
    background-color: white;
    height: 100%;
    border: 1px solid gray;
    border-radius: 10px;
}
.linetext p{
    margin-bottom: 0px;
}
.alianer{
    aspect-ratio: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    /* background-image: url(../../../public/photo/rr1.jpg); */
}
.p-222{
    padding: 20px 20px 0px 20px;
}
.backgrey{
    background-color: rgb(244, 244, 244);
}
.hiderss{
    height: 100%;
    background-color: #f1f7ff;
    border-radius: 10px;
    border: 1px solid #c7deff;
}
.hjddwk{
    padding: 5px;
    border: 1px solid #c7deff;
}
.tentes{
    display: flex;
    align-items: center;
}
@media (max-width:1200px){
    .hiddeb{
        display: none;
    }
}
.higerli div img{
    border-radius: 10px;
}
.linetext h6{
    color: #2c5a9f;
}
.hidertexter h2{
    font-size: 32px;
}
.borderless{
    border: 0px;
}
.relative{
    position: relative;
}
.centers h5{
    display: flex;
    justify-content: center;
    text-align: center;
}
.imgeininfo{
    width: 50px;
}
.numinfo{
    height: 100%;
    padding: 5px 20px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
}
.otercolor{
    background-color: #fcfaf7;
}
.numberinfot h5{
    color: #2c5a9f;
}
.center-text{
    text-align: center;
}
.centerlinnk{
    width: 100%;
    display: flex;
    justify-content: center;
}
.namersy img{
    width: 100%;
    height: 100%;
}
.erdoctertr{
    position: absolute;
    height: 100%;
}
.rowsssss{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.prrr-4 img{
    padding-right: 50px;
}
.llliner{
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    margin-top: -50px;
}
.adhresss{
    margin-top: 10px;
}
.bakcolorer{
    background-color: #08263e;
    position: relative;
}
.iiii img{
    width: 100px;
    margin-left: 30px;
}
.danger{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.ininin img{
    width: 200px;
}
.hinnerqwer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.alincenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.rwe-60{
    width: 65%;
}
.widtherhome{
    width: 337px;
    display: flex;
    justify-content: center;
}
@media(max-width:1400px){
    .widtherhome{
        width: 292px;
    }   
}
@media(max-width:1200px){
    .widtherhome{
        width: 323px;
    }   
}
.marginer992{
    margin-top: 0px;
}
.bookappoint{
    text-decoration: none;
}
@media(max-width:992px){
    .widtherhome{
        width: 360px;
    }  
    .marginer992{
        margin-top: 20px;
    }
    .alalaal{
        margin-bottom: 30px;
    } 
    .alalal{
        margin-top: 20px;
    }
}
@media(max-width:768px){
    .widtherhome{
        width: 100%;
    }   
}
@media(max-width:768px){
    .erdoctertr{
        position: relative;
    }
    .rowsssss{
        display: none;
    }
    .llliner{
        margin-top: 50px;
    }
}
.threeclass{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.backcolerd{
    max-width: 100%;
}
.backcolord{
    height: 100%;
    border: 2px solid #08263e46;
    background-color: #f4f9fe;
    border-radius: 10px;
}
.imgererr{
    width: 26px;
    position: absolute;
    right: 13px;
    top: 0px;
}
.doctorone img{
    display: block;
}
.alainercenter{
    display: flex;
    justify-content: center;
}
.alainercenter img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid #08263e4e;
    margin-bottom: 10px;
    background-color: white;
    box-shadow: 5px 5px 5px #08263e6f;
}

.ramacharia{
    align-items: center;
}
.ramachari h5{
    color: #0a1954;
    margin-bottom: 10px;
    text-align: center;
    text-shadow: 3px 3px 3px #0e395d0c;
}
.ramachari p:nth-child(2){
    color: white;
    text-align: center;
    text-shadow: 2px 2px 2px #08263e;
    margin-bottom: 10px;
}
.ramachari p:nth-child(3){
    color: #08263e;
    font-size: 17px;
    text-align: center;
}
.sdshjcsnc{
    display: flex;
    justify-content: center;
}
.ramacharia h1{
    color: #0a1954;
}
.callersinservice{
    text-decoration: none;
    background-color: #08263e;
    color: white;
    padding: 8px 20px;
    border-radius: 10px;
}
.anuman h5{
    text-align: center;
    margin-bottom: 14px;
    color: #0a1954;
}
.findwidther img{
    width: 26px;
    height: 26px;
}
.findwidther{
    display: flex;
    margin-right: 10px;
}
.rajendra{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.rajendra p{
    margin-bottom: 0px;
}
.bg-white{
    background-color: white;
}
.roandsize{
    border-radius: 5px;
}
.classone{
    display: block;
}
@media(max-width:1200px){
    .classone{
        display: none;
    }
}
.classtwo{
    display: none;
}
@media(max-width:1200px){
    .classtwo{
        display: block;
    }
}
@media(max-width:768px){
    .classtwo{
        display: none;
    }
}
.classthree{
    display: block;
}
@media(max-width:768px){
    .classthree{
        display: block;
    }
}
.indexer{
    position: relative;
}
.alimerdoctor{
    position: relative;
}
.namersya{
    position: relative;
}
@media(max-width:992px){
    .jsdcknbs{
        display: flex;
        justify-content: center;
    }
    .imgererr{
        position: relative;
        display: flex;
        right: 0px;
    }
}

.centerlia{
    display: flex;
    justify-content: center;
}
.centerlia img{
    width: 80%;
}
@media(max-width:970px){
    .centerlia img{
        width: 100%;
    }
}
.w-100er{
    margin-right: 5px;
    margin-bottom: 0px;
}
.numberslink h6{
    margin-bottom: 30px;
}
.crosaler{
    cursor: pointer;
}
.ramachari{
    text-align: center;
}