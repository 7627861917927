.outerdiv{
    position: absolute;
    width: 100%;
}
.hosinfirst{
    display: flex;
    margin-top: 55px;
}
.whatcall{
    padding: 5px;
    text-decoration: none;
}
.callwhat{
    padding: 5px;
    text-decoration: none;
}
.whatcall h6{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: white;
    font-weight: 600;
    background-color: green;
    padding: 5px;
}
.callwhat h6{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: white;
    font-weight: 600;
    background-color: #30327d;   
    padding: 5px;
}


.headertop{
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.headertwo-one p{
    margin: 0px;
}
.secheadertop{
    display: flex;
    align-items: center;
    padding-right: 12px;
}
.secheadertop p{
    align-items: center;
}
.firstheaderbox{
    display: flex;
    align-items: center;
    padding-left: 12px;
}
.headertwo-one{
    display: flex;
    height: auto;
    padding-left: 14px;
}
.headertwo-one p{
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    border-right: 2px solid #073f87;
}
.h5control h5{
    margin-top: 26px;
    color: #073f87;
    font-weight: 700;
}
.header-icon{
    display: flex;
    align-items: center;
}
.header-icon img{
    width: 24px;
}
.contactbutton{
    padding: 4px 20px;
    border: 2px solid #073f87;
    border-radius: 20px;
    text-decoration: none;
    color: white;
    background-color: #073f87;
    margin-left: 10px;
}
.hosinsecond{
    display: none;
}
@media (max-width:992px){
    .headertwo-one{
        display: none;
    }
    .hosinfirst{
        display: none;
    }
    .hosinsecond{
        display: flex;
    }
}
@media (max-width:400px){
    .headername{
        display: none;
    }
}


.serback{
    background-color: #f5f9ff;
}
.tittle{
    text-align: center;
    margin-bottom: 28px;
    color: #073f87;
}
.tittle h4{
    color: #0d6ce7;
}
.mainname{
    display: flex;
    align-items: center;
    text-align: center;
    color: #073f87;
}
.firstbox img{
    width: 100%;
}
.firstbox{
    display: flex;
    align-items: center;
}
.linkabout{
    margin-top: 30px;
    padding: 8px 28px;
    background-color: #2ea7a2;
    color: white;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    border-radius: 10px;
    box-shadow:3px 3px 3px rgba(255, 0, 0, 0.189);
}

.linkabouths{
    margin-top: 10px;
    padding: 8px 28px;
    background-color: rgb(254, 112, 112);
    color: white;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    border-radius: 10px;
    box-shadow:3px 3px 3px rgba(255, 0, 0, 0.189); 
}
.linkabouth{
    display: flex;
    justify-content: center;
}
.serviceouter{
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.servicebox{
    box-sizing: border-box;
    border: 1px solid #e3f0ff;
    width: 100%;
    height: 100%;
    padding: 25px 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 4px 4px 4px #073e8737;
}
.servicebox img{
    margin: 5px 0px;
    width: 100%;
}
.buttomer{
    bottom: 0px;
}
.buttop2{
    margin-bottom: 30px;
}
.buttop1{
    margin-bottom: 30px;
}
@media(max-width:1200px){
    .buttop1{
        margin-bottom: 0px;
    } 
    .buttop2{
        margin-bottom: 0px;
    }
}
@media(max-width:992px){
    .buttop2{
        margin-bottom: 30px;
    }
}
@media(max-width:768px){
    .buttop2{
        margin-bottom: 0px;
    }
}


.mainnamespin{
    padding: 5px;
    height: 30px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #90c0ff;
    border-radius: 50%;
}
.mainnamespin img{
    width: 22px;
}
.changestyle{
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: #e6f1ff;
    border: 1px solid #b0d1fd;
}
.changestyle h6{
    margin-left: 10px;
    margin-bottom: 0px;
}
.seriocn img{
    width: 130px;
    padding: 20px 25px;
    border: 1px solid #d1e5ff;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #ddeafb;
}
.margin{
    padding: 6px;
}
.ourpadder{
    padding: 10px 100px 20px;
}
.seriocn{
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width:1200px){
    .ourpadder{
        padding: 10px 0px 20px;
    }
}
@media (max-width:768px){
    .fullwidth{
        width: 100%;
        padding: 0px;
    }
    .seriocn img{
        padding: 0px;
        width: 90px;
        border: 1px solid #d1e5ff00;
        border-radius: 10px;
        box-shadow: 5px 5px 5px #ddeafb00;
    }
    .seriocn{
        padding: 13px;
        border: 1px solid #d1e5ff;
        border-radius: 10px;
        box-shadow: 5px 5px 5px #ddeafb;
    }
}



.maininfo{
    padding: 8px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.secmaininfo{
    width: 100%;
    padding: 20px 10px;
    background-color: #cfe3fd;
    border: 1px solid #85b8fa;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #073e874f;
}
.secmaininfo img{
    width: 85px;
    margin-bottom: 8px;
}


.conback{
    background-color: #073f87;
    margin-top: 15px;
}
.secmaininfo1{
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    color: white;  
}
.secmaininfo1 img{
    width: 85px;
    margin-bottom: 8px;
}
.highwi{
    height: 28px;
    margin-right: 20px;
}
.textcenter{
    margin-bottom: 10px;
}
.conhomeouter{
    width: 100%;
    height: 100%;
    padding: 30px;
    background-position: center;
    background-size: cover;
    background-color: #073f87;
}
.label{
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 0px 4px 0px;
}
.inputhome{
    width: 100%;
    height: 42px;
    border-radius: 10px;
}
.texthome{
    width: 100%;
    height: 80px;
    border-radius: 10px;
}



.header{
    display: flex;
    background-color: #073f87;
    justify-content: space-between;
    width: 100%;
    top: 0;
    z-index: 1;
}
.headone{
    background-color: #94bdc4;
    padding: 25px 20px;
    border-radius: 0px 15px 15px 0px;
}
.headone h5{
    margin: 0px;
    color: white;
}
.headicon{
    display: flex;
    float: right;
    margin: 0px;
    align-items: center;
}
.headicon li{
    list-style-type: none;
}
.Menu-top{
    display: flex;
    align-items: center;
    margin: 0px;
}
.liver{
    display: flex;
    width: 100%;
}
@media(max-width:992px){
    .liver{
        display: block;
    }
}
.Menu-top li{
    list-style-type: none;
    align-items: center;
    position: relative;
}
.headsec{
    display: flex;
    align-items: center;
}
.headerf{
    display: flex;
}
.headerthree{
    margin-right: 40px;
    display: flex;
    align-items: center;
}
.headicopad{
    background-color: white;
    margin: 5px;
}
.menubutton{
    padding: 4px;
    background-color: white;
    border: 3px solid black;
}
.menubutton img{
    width: 30px;
}
.headfour{
    display: none;
}
.Menu-top li:hover .submenu{
    display: block;
}
.submenu{
    position: absolute;
    display: none;
}
.submenusub{
    padding: 10px 20px;
    background-color: #073f87;
    margin: 2px;
    width: 200px;
}
@media (max-width:992px){
    .headerthree{
        display: none;
    }
}
@media (max-width:690px){
    .headsec{
        display: none;
    }
    .contacsize{
        font-size: 14px;
    }
    .headfour{
        display: flex;
        align-items: center;
        margin-right: 25px;
    }
}
@media (max-width:300px){
    .contacsize{
        font-size: 12px;
    }
    .headfour{
        margin-right: 5px;
    }
}



/* mobile-menu */
.mobfirst{
    display: flex;
    padding: 10px 10px;
    align-items: center;
    justify-content: space-between;
    background-color: #073f87;
}
.mobfirst h6{
    padding-left: 20px;
    margin-bottom: 0px;
    color: white;
}
.mobicopad{
    border: 0px;
    background-color: #073f87;
}
.mobmenu{
    margin: 0px;
    padding-left: 2px;
    padding-right: 2px;
}
.mobmenu li{
    list-style-type: none;
}
.submenusubmob{
    padding: 10px 20px;
    background-color: #073f87;
    margin: 2px 0px;
    width: 100%;  
}
.sermenu{
    display: flex;
    padding-right: 10px;
    background-color: #073f87;
}
.mobmenua{
    margin: 0px;
    padding-left: 20px;
    padding-right: 0px;
}
.mobmenua li{
    list-style-type: none;
}
.backmob{
    background-color: #f4f8f2;
}
.mobheader{
    display: none;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 100;
    background-color: white;
}


.covemeaning{
    padding: 5px;
    display: none;
}
@media (max-width:768px){
    .covemeaning{
        display: block;
    }
}


/* footer */
.footback{
    background-color: #073f87;
}
.footul{
    margin: 0px;
    padding-left: 0px;
    color: white;
}
.footul li{
    list-style-type: none;
    padding: 2px 5px;
}
.footceter{
    display: flex;
    align-items: center;
    padding: 10px 0px;
    justify-content: center;
}
.displayer{
    display: flex;
}
.footmar p{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}
.footpadle{
    margin-right: 10px;
}
.linkfoot{
    text-decoration: none;
    display: flex;
    align-items: center;
    color: white;
}
.footmap{
    width: 100%;
    height: 100px;
}
.footicon{
    padding: 0px;
    margin: 0px;
}
.appaddlink{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    margin-left: 0px;
    color: white;
    text-decoration: none;
}
.footalik{
    text-decoration: none;
}
.b7cdd0{
    background-color: #3086f7;
}

/* display core */
.coredis{
    width: 100%;
    height: 100%;
    background:linear-gradient(rgba(255, 255, 255, 0.086),rgba(255, 255, 255, 0.141)), url(../../public/photo/hospitalinfo.jpg);
    background-size: cover;
    padding: 20px 5px;
    text-align: center;
    overflow: hidden;
    overflow: scroll;
    border: 3px solid #cce0fc;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #073e8719;
}
::-webkit-scrollbar{
    display: none;
}
.allcorevalue{
    height: 100%;
    width: 800%;
    display: flex;
}
.corevaluebody{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.corevaluebody h5{
    font-weight: 800;
    color: #073f87;
}
.corevaluebody p{
    font-weight: 700;
    color: #35145c;
}
.mainfootsec{
    width: 100%;
    height: 30px;
    background-color: #b7cdd0;
    border-bottom:1px solid rgba(0, 0, 0, 0.332);
    border-top: rgb(53, 53, 253);
}


/* banner */
.banner{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-position: center;
}
.bannertextf{
    display: flex;
    color: white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.599);
    text-decoration: none;
    text-align: center;
    justify-content: center;
    padding: 0px 20px;
}
.aboutbanner{
    background-image:url(../../public/photo/aboutbanner.jpg);
}
.mainbannertext{
    text-shadow: 4px 4px 4px rgba(255, 255, 255, 0.293);
}


/* mission and vission */
.mission{
    text-align: center;
    color: #073f87;
    padding: 30px 50px 10px;
}
.missioncon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mission h6{
    line-height: 26px;
}
.missiobban{
    width: 150px;
    height: 150px;
    position: absolute;
    background-image:linear-gradient(rgba(255, 255, 255, 0.818),rgba(255, 255, 255, 0.758)), url(../../public/photo/target\ \(1\).png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -2;
}
.vissiobban{
    width: 150px;
    height: 150px;
    position: absolute;
    background-image:linear-gradient(rgba(255, 255, 255, 0.818),rgba(255, 255, 255, 0.758)), url(../../public/photo/focus.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -2;   
}
.vissionhigh{
    height: 100%;
}
.mission{
    top: 0px;
}
@media (max-width:500px){
    .mission{
        padding: 30px 5px;
    }
}


/* services */
.servicesbanner{
    background-image: url(../../public/photo/servicebanner.jpg);
    width: 100%;
    background-size: cover;
}
.contactbanner{
    background-image: url(../../public/photo/contactusbanner.jpg);
    width: 100%;
    background-size: cover;  
}
.advancembanner{
    background-image: url(../../public/photo/admbanner.jpg);
    width: 100%;
    background-size: cover;   
}


.contactimg img{
    width: 100%;
}
.directlink{
    text-decoration: none;
    color: white;
    background-color:#073f87;
    box-shadow: 3.5px 3.5px 3.5px #073e8740;
}



/* service body */
.linkebox{
    padding: 8px;
    margin-bottom: 14px;
    background-color: #d2e4fb;
}
.directtittle{
    color: #073f87;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.297);
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0px;
}
.linkiconbox{
    text-align: center;
    padding: 40px;
}
.linkiconbox img{
    width: 120px;
    margin-bottom: 20px;
}
.linkiconbox h5{
    color: #073f87;
    font-weight: 700;
}
.mainiconli{
    width: 100%;
}
.likeiconcon{
    display: flex;
    width: 600%;
}
.likehidder{
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: scroll;
}
.likeextra{
    padding: 20px;
}
.addreesmadtop{
    margin-top: 10px;
}
.infoser{
    padding: 16px 5px;

}
.infoser h5{
    text-align: center;
    color: #073f87;
}
.infoser p{
    margin-bottom: 20px;
}
.big{
    display: block;
}
.small{
    display: none;
}
@media (max-width:992px){
    .big{
        display: none;
    }
    .small{
        display: block;
    }
}
@media (max-width:350px){
    .likeextra{
        padding: 10px;
    }
}
@media (max-width:400px){
.linecontrol{
    display: none;
}
}
.onebanner{
    background-position: center;
    background-size: cover;
    background-image: url(../../public/photo/onebanner.jpg);
}
.threebanner{
    background-position: center;
    background-size: cover;
    background-image: url(../../public/photo/threebanner.jpg);
}
.fourbanner{
    background-position: center;
    background-size: cover;
    background-image: url(../../public/photo/fourbanner.jpg);
}
.fivebanner{
    background-position: center;
    background-size: cover;
    background-image: url(../../public/photo/fivebanner.jpg);
}
.sixbanner{
    background-position: center;
    background-size: cover;
    background-image: url(../../public/photo/sixbanner.jpg);
}

/* new section */
.Menulike{
    color: white;
    text-decoration: none;
    padding: 20px 14px;
    display: flex;
    align-items: center;
    margin: 0px;
    font-size: 14px;
}

/* New Header two*/
header{
    background-color: white;
    z-index: 2;
    width: 100%;
}
.newlogo img{
    width: 400px;
    margin: 5px 0px;
}
@media(max-width:1200px){
    .newlogo img{
        width: 260px;
        margin: 5px 0px;
    }   
}
.rowheader{
    display: flex;
    align-items: center;
    padding: 10px 0px;
    justify-content: space-between;
}
.newlink{
    text-decoration: none;
    display: flex;
    align-items: center;
}
.newlink h6{
    margin-bottom: 0px;
    padding: 20px 12px;
    color: #073f87;
}
.newinnerli{
    display: flex;
    margin-bottom: 0px;
}
.newinnerli li{
    list-style-type: none;
}
.newcon img{
    width: 60px;
    margin-left: 20px;
}
.newlogoca img{
    width: 30px;
    margin-right: 10px;
    margin-left: 0px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
}
.newlogoca{
    display: flex;
    align-items: center;
    background-color: #073f87;
    padding: 10px 14px;
    margin-bottom: 5px;
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.236);
    border-radius: 4px;
}
.newlogoca h6{
    margin-bottom: 0px;
    color: white;
}
.newconin{
    position: absolute;
    z-index: 2;
    margin-left: -180px;
    padding-top: 20px;
    display: none;
}
.newcon:hover .newconin{
    display: block;
}
.newliim img{
    height: 30px;
    margin-right: 12px;
    display: flex;
    top: 0px;
}
.newliim{
    width: 50px;
    display: flex;
    justify-content: center;
}
.newlinkbox{
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 18px;
}
.newlinkbox h6{
    margin-bottom: 0px;
    color: #073f87;
    font-size: 17px;
    line-height: 24px;
}
.newlinkboxs:hover{
    background-color: white;
}
.newlinkboxs h6{
    color: white;
    margin-left: 10px;
}
.newlinkboxs{
    text-decoration: none;
    display: flex;
    width: 100%;
    background-color: #073f87;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #073f87;
}
.newlinkboxs h6{
    margin-bottom: 0px;
    color: #073f87;
    font-size: 17px;
    line-height: 24px;
}
.newtittle{
    display: flex;
    margin-bottom: 14px;
    text-align: center;
    padding: 0px 14px;
}
.newtittle h4{
    border-bottom: 2px solid #073f87;
    padding-bottom:14px;
}
.newouter{
    position: absolute;
    z-index: 2;
    left: 0px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    display: none;
}
.newcontainer{
    width: 1064px;
    padding: 14px;
    background-color: white;
}
.newinnerli li:hover .newouter{
    display: flex;
}
.newlinker{
    display: block;
}
.newmenu{
    padding: 3.4px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.newmenu img{
    width: 36px;
}
.newmenua{
    margin-left: 20px;
}
.newmenua img{
    height: 50px;
}
.newmobme{
    display: none;
}
.newmobappt{
    display: block;
}
@media (max-width:992px){
    .newlogo img{
        width: 400px;
    }
    .newlinker{
        display: none;
    }
    .newmobme{
        display: flex;
    }
}
@media (max-width:380px){   
    .newmobappt{
        display: none;
    }
}


/* footer */

.newfootlo{
    width: 100%;
    display: flex;
    justify-content: center;
}
.newfootlo img{
    width: 80px;
}
.textfoot h6{
    line-height: 28px;
}
.footle{
    display: flex;
    justify-content: left;
}
.newdirectli{
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #073f87;
}
.mro{
    justify-content: center;
    width: 30px;
}
.newfootmap{
    width: 100%;
    height: 180px;
}
.a073f87{
    background-color: #073f87;
}
.mtfoot{
    margin-top: 60px;
}
.homeblog{
    color: white;
    background-color: #073f87;
    height: 473px;

    align-items: center;
}
.alinitemcenter{
    display: flex;
    align-items: center;
}
.homebloga h5{
    color: white;
    margin: 0px 0px 30px;
}
.homebloga h1{
    color: white;
    margin-bottom: 0px;
}
.headicona{
    margin-left: 0px;
    padding-left: 0px;
    display: flex;
}
.posireli{
    position: absolute;
    display: flex;
    justify-content: end;
}
.mb5{
    padding: 70px 0px;
    background-color: #f5f9fe;
}
.headicopada{
    border-radius: 50%;
    display: flex;
    width: 32px;
    justify-content: center;
    border-radius: 50%;
    margin-right: 6px;
    background-color: white;
    padding: 7px 5px;
}
.headicopada img{
    width: 18px;
}
.newblog img{
    width: 100%;
}
.newblog{
    margin-bottom: 15px;
}
.blogarea{
    border: 1px solid #e0ecfb;
    padding: 0px 10px;
    padding-top: 10px;
    width: 25%;
}
.blogarea h5{
    line-height: 28px;
    color: rgb(57, 57, 57);
}
.blogarea h5::after{
    display: flex;
    content: " ";
    margin-top: 10px;
    height: 2px;
    background-color: #073f87;
    width: 20%;
}
.linkinnew{
    text-decoration: none;
    color: #073f87;
    margin-bottom: 30px;
    font-weight: 600;
}
.minerblog{
    position: absolute;
    z-index: 2;
    text-align: center;
}
.rowofblogp{
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
}
.linkinnew h6{
    font-weight: 700;
}
.relpo{
    position: relative;
}
.colortoper{
    height: 50px;
    width: 100%;
    background-color: #073f87;
    margin: 0px;

    padding: 0px;
}
.rowhome{
    display: flex;
    width: 100%;
}
.rowhomehi{
    width: 100%;
    overflow: hidden;
}
.firstmenu{
    position: fixed;
    z-index: 15;
    top: 0px;
    width: 100%;
    background-color: white;
}
.secondmenu{
    position: fixed;
    z-index: 16;
    width: 100%;
    background-color: white;
    height: 100%;
    width: 100%;
    top:0px;
    padding-bottom: 70px;
    overflow: hidden;
    overflow-y: scroll;
}
.innersfirst{
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    text-align: center;
}
.innersfirst h4{
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    text-align: center;
}
.coninboxadd{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 14px;
    color: white;
}
.addintittle{
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
    margin-bottom: 30px;
}
.secchild{
    padding: 70px 0px;
    background-image: url(../../public/photo/bannerser.jpg);
    background-size: cover;
    margin: 70px 0px;
    background-position: center;
    background-color: #073f87;
}
@media (max-width:500px){
    .emailnewfo h6{
        font-size: 14px;
    }
}

.tittlea{
    text-align: center;
    margin-bottom: 8px;
    color: #073f87;
}
.newlinkboxb{
    border: 1px solid rgb(235, 235, 235);
    display: flex;
    align-items: center;
    padding: 5px;
}
.newlinkboxb h6{
    margin-bottom: 0px;
}
.imgaddincon img{
    width: 100%;
}
.alicecenter{
    display: flex;
    align-items: center;
}
.hidderadd{
    opacity: 0;
}
.linkinsera{
    background-color: rgb(235, 235, 235);
    padding: 10px 10px 0px;
    border: 4px solid rgb(215, 215, 215);
    color: black;
}
.linkinser{
    background-color: white;
    padding: 10px 10px 0px;
    border: 4px solid white;
    color: white;
}
.inimgera img{
    margin: 0px;
}
.inimgera{
    margin: 0px;
    padding: 0px;
    width: 40px;
}
.subbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.155), rgba(0, 0, 0, 0.199)), url(../../public/photo/subbanner.jpg);
    background-size: cover;
}
.newaboutbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.155), rgba(0, 0, 0, 0.199)), url(../../public/photo/aboutbanner.jpg);
    background-size: cover;
}
.newcontactbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.155), rgba(0, 0, 0, 0.199)), url(../../public/photo/contactusbanner.jpg);
    background-size: cover;
    background-position: center;
}
.gallerybanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.155), rgba(0, 0, 0, 0.199)), url(../../public/photo/gallerynewbanner.jpg);
    background-size: cover;
    background-position: center;
}
.blogtbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.155), rgba(0, 0, 0, 0.199)), url(../../public/photo/blogbanner.jpg);
    background-size: cover;
    background-position: center;
}
.ourdoctorbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.155), rgba(0, 0, 0, 0.199)), url(../../public/photo/ourdoctorbanner.jpg);
    background-size: cover;
    background-position: right;
}
.textourdoc{
    text-align: center;
}
.textourdoc h1{
    color: #073f87;
}

/* Services */
.serboxaaaaa{
    border-top: 10px solid #3086f7;
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
    background-color:#3086f7;
}
.firstboxaaaaa{
    width: 50%;
    height: 40px;
    background-color:white;
    float: right;
}
.threaaa{
    width: 100%;
    height: 250px;
    justify-content: center;
    background-color: rgb(255, 255, 255);
}
@media (max-width:1400px){
    .threaaa{
        height: 268px;
    }
}
@media (max-width:1200px){
    .threaaa{
        height: 305px;
    }
    .newcontainer{
        width: 900px;
    }
}
@media (max-width:992px){
    .threaaa{
        height: 270px;
    }
}
@media (max-width:992px){
    .threaaa{
        height: auto;
    }
}
.secondboxaaaaa{
    width: 50%;
    height: 40px;
    float: left;
    margin-bottom: 0px;
    background-color: rgb(255, 255, 255);
}
.thaaaa{
    display: flex;
    justify-content: center;
    width: 90px;
    height: 90px;
    margin-bottom: 14px;
    margin-top: 4px;
}
.thaaaa img{
    height: 100%;
}
.topimgser{
    width: 100%;
    display: flex;
    justify-content: center;
}
.threaaa h5{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: black;
}
.threaaa h6{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: black;
}
.backnews{
    background-image: url(../../public/photo/bannerser.jpg);
    margin: 30px 0px 70px;
    background-size: cover;
}
.doctorlistbo{
    border-bottom: 5px solid #073f87;
}
.footerditail{
    background-color:#073f87;
    position: relative;
    z-index: 5;
}
@media (max-width:992px){
    .footerditail{
        background-color:#073f87;
        position: relative;
        z-index: 20;
    }
}
.buttnew{
    padding: 14px 20px;
    background-color: white;
    display: flex;
    border: 0px;
}
.buttnew h6{
    margin-bottom: 0px;
}
.decoremove{
    text-decoration: none;
}

.innerbutton{
    display: flex;
    width: 100%;
    justify-content: center;
}
.buttnewa{
    padding: 14px 20px;
    display: flex;
    border: 0px;
}
.buttnewa h2{
    margin-bottom: 0px;
    color: white;
}
.linkabouta{
    width: 100%;
    margin-top: 30px;
    padding: 8px 0px;
    background-color: #073f87;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    margin-bottom: 30px;
}
.fixicon{
    position: fixed;
    display: flex;
    z-index: 24;
    right: 10px;
    top: 50%;
    bottom: 50%;
    margin-right: 10px;
    align-items: center;
    justify-content: right;
}
@media (max-width:992px){
    .fixicon{
        display: none;
    } 
}
.iconboxsli{
    position: relative;
    z-index: 48;
}


/* contact box */
.conconbox{
    padding: 8px 20px;
    color: #944f9f;
    background-color: white;
    border: 2px solid #944f9f;
}
.condiv a{
    text-decoration: none;
}
.conconbox h5{
    margin-bottom: 0px;
}
.conbox{
    display: flex;
    background-color: #30327d;
    justify-content: space-around;
    padding: 20px;
}
.imgcont{
    display: flex;
}
.context{
    display: flex;
    align-items: center;
    color: white;
}
.context h6{
    margin-bottom: 0px;
}
.mtfoot{
    position: relative;
    z-index: 20;
    background-color: white;
}
.imgconbox img{
    width: 70px;
    padding: 8px;
    background-color: white;
    border-radius: 50%;
    border: 4px solid #944f9f;
}
.imgconbox{
    margin-right: 20px;
}
.condiv{
    display: flex;
    align-items: center;
}
.newfixheader{
    position: fixed;
    background-color: white;
    z-index: 11;
    display: none;
    box-shadow: 2px 2px 2px #dde0e4;
}
@media (max-width:992px){
    .conbox{
        display: block;
    }
    .imgcont{
        justify-content: center;
    }
    .condiv{
        margin-top: 10px;
        justify-content: center;
    }
    .martitleadd{
        margin-bottom: 50px;
    }
}
@media (max-width:768px){
    .imgcont{
        display: block;
    }
    .newlogo img{
        width: 300px;
    }
    .imgconbox{
        display: flex;
        justify-content: center;
        margin: 0px;
        margin-bottom: 20px;
    }
    .condiv{
        margin-top: 20px;
    }
    .conbr{
        display: none;
    }
    .context{
        display: flex;
        text-align: center;
    }
    .lineot{
        font-size: 14px;
    }
}
.blogbox{
    border-top: 0px solid #073f87;
    padding-top: 10px;
}


/* Our doctor */
.ourdoc img{
    width: 36px;
    border: 2px solid #073f87;
    border-radius: 50%;
    padding: 2px;
}

/* Review SEction */
.reviewone{
    text-align: center;
}
.reviewone h5{
    color: white;
}
.reviewone h1{
    margin-top: 30px;
    margin-bottom: 40px;
    color: white;
}
.secreview{
    margin: 70px 0px 0px;
    padding: 70px 0px;
    background-image: url(../../public/photo/bannerser.jpg);
    background-position: center;
    background-size: cover;
}
.reviewtwo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.reviewtwo img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 5px solid white;
}
.threereview img{
    width: 24px;
    padding: 4px;
}
.threereview{
    display: flex;
    margin-bottom: 10px;
}
.fourreview{
    color: white;
}
.reviewsix{
    padding: 5px;
}
.reviewfive{
    border-left: 2px solid rgba(255, 255, 255, 0.186);
    border-top: 2px solid rgba(255, 255, 255, 0.186);
    padding: 30px 5px;
}
.doctorone{
    display: flex;
    align-items: center;
    justify-content: center;
}
.doctorone img{
    margin-top: 10px;
}
.textwhi{
    color: black;
}
.textdocone h5{
    color: #073f87;
    text-align: center;
    margin-bottom: 5px;
}
.textdocone h6{
    line-height: 22px;
    text-align: center;
    margin-bottom: 15px;
}
.docnamein{
    width: 280px;
    padding: 10px 8px;
}
.doctertittle{
    background-color: white;
    color: white;
    padding: 12px 0px 6px;
}
.doctertittle h4{
    margin-bottom: 0px;
}
.linerdocter h4{
    margin: 20px 0px;
    color: #073f87;
}
.otherbody{
    display: flex;
    justify-self: center;
    width: 100%;
}
.selectbot{
    border-bottom: 0px solid #073f87;
}
.docmaininf{
    display: flex;
    justify-content: center;
}
.docterdis{
    display: flex;
}
.sermainbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.155), rgba(0, 0, 0, 0.199)), url(../../public/photo/sernewbanner.jpg);
    background-size: cover;
}
.firstlistser{
    display: block;
}
.secondlisser{
    display: none;
}
.chafirst h5{
    color: #073f87;
    height: 80px;
}

.chafirst{
    padding: 20px 10px 30px;   
}


/* media */
    .secmedia{
        margin: 40px 0px 70px;
    }
.mediaheader h1{
    color: #073f87;
}
.textinmedia{
    padding: 20px 10px;
    height: 100%;
    background-color: #dbeafe;
    color: #073f87;
}
.latestpost{
    height: 60px;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    width: 160px;
    border-top: 60px solid #bad7fc;
    border-left: 26px solid #073f87;
    border-right: 26px solid #073f87;
}
.latestposth4{
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    width: 160px;
}
.seclater{
    border: 26px solid #073f87;
    border-top: 60px solid #073f87;
    position: relative;
}
.relative{
    position: relative;
}
.absul{
    position: absolute;
    z-index: 2;
}
.noreinfo{
    text-decoration: none;
    color: #073f87;
    font-weight: 700;
}
.noreinfo::after{
    content: " ";
    display: flex;
    width: 50%;
    margin-top: 5px;
    height: 2px;
    background-color: #073f87;
}
.latesannu{
    background-color: #073f87;
    color: white;
    padding: 40px;
}
.marlikm{
    text-decoration: none;
    margin-top: 0px;
    padding-top: 0px;
}
.numcen{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #073f87;
    margin: 5px;
    height: 30px;
    color: #073f87;
    background-color: #b7d6fe;
}
.rigthro{
    rotate: -180deg;
}
.numcen h6{
    margin-bottom: 0px;
}
.secnumber{
    margin-top: 30px;
}
@media (max-width:1200px){
    .reviewone{
        text-align: center;
    }
    .reviewtwo{
        margin-bottom: 30px;
    }
}
@media (max-width:992px){
    .reviewone{
        text-align: center;
    }
    .reviewtwo{
        margin-bottom: 0px;
    }
    .docterdis{
        display: none;
    }
    .blogbox{
        border-top: 0px solid #073f87;
        padding-top: 0px;
    }
    .firstlistser{
        display: none;
    }
    .secondlisser{
        display: block;
    }
    .mediaheader{
        text-align: center;
    }
}
@media (max-width:768px){
    .relpo{
        position: relative;
    }
    .blogbox{
        position: relative;
    }
    .reviewone{
        text-align: center;
    }
    .reviewtwo{
        margin-bottom: 30px;
    }
    .chafirst h5{
        color: #073f87;
        height: 100px;
    }
}


/* buttons Button*/
.conforlas{
    display: none;
    left: 8%;
    bottom: 5%;
    position: fixed;
    z-index: 3;
    max-width: 300px;
    padding: 20px;
}
.innerform{
    padding: 20px;
    border: 1px solid #adb0ff;
    background-color: #e4e5fff7;
}
.contitle{
    display: flex;
    text-align: center;
}
.contitle h5{
    font-weight: 700;
    color: #30327d;
    margin-bottom: 10px;
}
.inconlas{
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    border: 1px solid #073f87;
    background-color: #f6f6ff;
    padding-left: 10px;
}
.meassageconfor{
    width: 100%;
    height: 60px;
    border: 1px solid #073f87;
    background-color: #f6f6ff;
}
.subformbox{
    padding: 8px 20px;
    background-color: #944f9f;
    color: white;
    border: 0px;
}
.subconfor{
    display: flex;
    margin-top: 13px;
    width: 100%;
    justify-content: center;
}
.errorbox{
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    margin-top: 20px;
}
.errorbox img{
    width: 40px;
    background-color: white;
    border-radius: 50%;
}
/* controller */
.conboxmain{
    position: fixed;
    bottom: 14%;
    z-index: 30;
    left: 2%;
    transition-duration: 2s;
}
.controlone img{
    width: 65px;
}
.controlone{
    border-radius: 50%;
    width: 65px;
}
.controltwo img{
    width: 65px;
    margin-bottom: -75px;
    position: absolute;
    z-index: -1;
    transition: 0.8s;
    opacity: 0;
}
.controlthree img{
    width: 65px;
    margin-bottom: -150px;
    position: absolute;
    z-index: -1;
    transition: 0.8s;
    opacity: 0;
}
.controlthreea img{
    width: 65px;
    margin-bottom: -150px;
    position: absolute;
    z-index: 1;
    transition: 0.8s;
    opacity: 1;
}
.controlthreea{
    width: 65px;
    margin-bottom: -150px;
    position: absolute;
    z-index: 1;
    transition: 0.8s;
    opacity: 1;

}
.conboxmain:hover .controltwo img{
    position: relative;
    margin-bottom: 20px;
    transform: rotate(-360deg);
    opacity: 1;
}
.conboxmain:hover .controlthree img{
    position: relative;
    margin-bottom: 20px;
    transform: rotate(-360deg);
    opacity: 1;
}
@media (max-width:1200px){
    .conforlas{
        left: 9%;
    }
}
@media (max-width:992px){
    .conforlas{
        left: 12%;
    }
}
.visis img{
    width: 200px;
    margin-bottom: 20px;
    border-radius: 50%;
}
.bordervalue{
    padding: 10px;
    height: 100%;
    border: 1px solid white;
    text-align: center;
    color: white;
}
.bordervaluea{
    margin-top: 10px;
    padding: 10px;
    height: 100%;
    border: 1px solid #073f87;
    text-align: center;
    color: #073f87;
}
/* mobile controller */
.mobilecontrol{
    position: fixed;
    bottom: 0px;
    z-index: 5;
    width: 100%;
    padding: 10px;
    display: none;
    background-color: #073f87;
}
.mobicon{
    display: flex;
    text-align: center;
    color: white;
    justify-content: center;
    margin-top: 8px;
}
.buttonmob{
    display: flex;
    justify-content: center;
}
.conbutt{
    background-color: #944f9f;
    color: white;
    border: 0px;
    padding: 8px 20px;
}
.sooboxone{
    position: absolute;
    right: 10px;
}
.sooin{
    width: 50px;
    margin-top: -235px;
}
.soonon img{
    width: 50px;
}
.soointo{
    margin-top: 8px;
}
.mobconform{
    width: 100%;
    left: 0%;
    height: 100%;
    z-index: 18;
    background-color: rgba(0, 0, 0, 0.715);
    bottom: 0%;
    top: 0;
    overflow: hidden;
    overflow-y: scroll;
    align-items: center;
    display: none;
    position: fixed;
    padding: 20px;
}
.innermobcon{
    align-items: center;
    margin-top: 10%;
}
.homecall img{
    width: 28px;
    padding: 2px;
    /* border-radius: 50%; */
}
.textimg{
    display: flex;
    align-items: center;
}
.textimg h6{
    color: white;
    margin-bottom: 0px;
    margin-left: 10px;
}
.paddimg img{
    margin: 0px 3px;
}
.display-flex{
    display: flex;
}
.iconcenter{
    width: 100%;
    justify-content: center;
}
.backchan{
    background-color: #073f87;
    padding: 0px;
}
@media (max-width:386px){
    .sooin{
        margin-top: -260px;
    }
}
@media (max-width:768px){
    .mobilecontrol{
        display: block;
    }
    .conboxmain{
        display: none;
    }
}
@media (max-width:500px){
    .fhu{
        display: none;
    }
}
@media (max-width:580px){
    .newmenua{
        display: none;
    }
}
@media(max-width:400px){
    .newlogo img{
        width: 200px;
        margin: 10px 0px;
    }
}


/* new ciode */
.secmaininfoa{
    width: 100%;
    padding: 10px 5px;
    background:linear-gradient(#073e8756,#073e87bf);
    border: 1px solid #85b8fa;
    border-radius: 10px;
    box-shadow: 8px 8px 8px #073e876a;
    color: white;
}
.secmaininfoa img{
    width: 55px;
    margin-bottom: 5px;
}
.docnameina{
    width: auto;
    padding: 8px 8px;
    height: 100%;
}
.backpadddo{
    background:linear-gradient(#073e870b,#073e8719);
    padding: 10px 8px;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #61a5fd;
    box-shadow: 8px 8px 8px #073e8760;
}
.tittle{
    font-size: 42px;
}
.greybackness{
    background-color: #ededed;
}
.arrower{
    position: absolute;
    margin-top: 5px;
    right: 0px;
    display: flex;
    justify-content: right;
    margin-right: 5px;
}
.arrower img{
    display: flex;
    width: 24px;
    margin-right: 0px;
    right: 0px;
    z-index: 1;
}
.h6ofser h6{
    font-size: 16px;
}
.laaa h4{
    display: none;
}
.doctertittle{
    background-color: #073f87;
    color: white;
    padding: 0px 0px 0px;
}
.newdirectli{
    border: 0px;
}
.bgvision{
    background-color: #f0f2f7;
}
.innnerh1 h1{
    color: #073f87;
}
.reviewone{
    color: white;
    margin-bottom: 20px;
}
.biggersmall{
    background-image:linear-gradient(#073e87b9, #073e87b8), url(../../public/photo/vismisson.jpg);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px 20px;
}
.numberslink h4{
    color: white;
}
.numberslink h6{
    color: white;
}
.pya-5{
    padding: 50px 20px;
}
.bookappoint{
    background-color: white;
    color: #073f87;
    font-weight: 600;
    padding: 8px 12px;
    border: 0px;
    margin-top: 10px;
}
.homeinback{
    background-color: #d1edf4;
}
.alianer{
    width: 100%;
    display: flex;
}
@media (max-width:992px){
    .laaa h4{
        display: flex;
    }
    .pya-5{
        padding: 0px 20px;
    }
    .doctertittle{
        background-color: #073f87;
        color: white;
        padding: 12px 0px 6px;
    }
    .newdirectli{
        display: flex;
        justify-content: center;
        border-bottom: 2px solid #073f87;
    }
    .alianer{
        width: 80%;
    }
}
.hilinkk{
    position: absolute;
    background-color: white;
    box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.171);
    z-index: 22;
    display: none;
}
.newinnerli li:hover .hilinkk{
    display: block;
}
.newlinkboxs:hover{
    background-color: white;
}
.newlinkboxs h6{
    color: white;
}
.decoremove:hover .newlinkboxs{
    background-color: white;
}
.liselectdoc:hover .newlinkboxs h6{
    color: black;
}
.bodiess{
    height: 50px;
    border: 3px solid black;
    border-radius: 10px;
}
.judyj{
    display: flex;
    justify-content: space-between;
}
.linesjbcdhzmn{
    display: flex;
    justify-content: center;
}
.aliyamav{
    height: 170px;
}
.aliyaramaya{
    height: 250px;
    padding-left: 10px;
    padding-right: 10px;
}
.aliyaramayaaa{
    height: 290px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
}
@media(max-width:768px){
    .linesjbcdhzmn{
        justify-content: center;
        margin-top: 20px;
    }
    .alianer{
        width: 100%;
    }
}
@media(max-width:580px){
    .aliyaramaya{
        height: auto;
        width: 100%;
    }
    .alianer{
        width: 150px;
    }
}
.papper{
    background-color: #073f87;
    color: white;
    height: auto;
    width: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
}

@media(max-width:1400px){
    .aliyamav{
        height: 190px;
    }
    .aliyaramayaaa{
        height: 310px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }
}
@media(max-width:768px){
    .aliyamav{
        height: auto;
    }
    .aliyaramayaaa{
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }
}
@media (max-width:1400px){
    .homeblog{
        height: 503px;
    }
    .blogarea{
        width: 33.3333%;
    }
}
@media (max-width:1200px){
    .homeblog{
        height: 490px;
    }
    .blogarea{
        width: 33.3333%;
    }
}
@media (max-width:992px){
    .homeblog{
        height: 492px;
    }
    .blogarea{
        width: 50%;
    }
}
@media (max-width:768px){
    .homeblog{
        height: auto;
    }
    .rowofblogp{
        position: relative;
        width: 100%;
        margin: 0px;
        text-align: center;
    }
    .minerblog{
        position: relative;
        z-index: 2;
    }
    .relpo{
        width: 100%;
        padding: 0px;
    }
    .homeblog h5{
        margin: 30px 0px;
    }
    .homeblog h1{
        margin-bottom: 30px;
    }
    .blogarea{
        width: 100%;
    }
}
.filtersha{
    padding: 0px 10px;
    box-shadow: 0px 0px 0px white;
    justify-content: center;
    display: flex;
    height: 190px;
    justify-content: center;
}
.filtersha h6{
    margin: 0% 5px;
    text-align: center;
    color:#073f87;
    margin-top: 116px;
}
.filtersha img{
    position: absolute;
    height: 100px;
    border: 0px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px white;
  animation: vibrate 3s  infinite;
    animation-duration: 2s;
    margin-bottom: 0px;
    padding-bottom: 0px;
    animation-iteration-count: infinite;
}
.textdicore{
    text-decoration: none;
}
@keyframes vibrate{
    0%{margin: 0px 0px  0px 0px;}
    25%{margin: 5px 10px 0px 0px;}
    50%{margin: 10px 0px 0px 0px;}
    75%{margin: 5px 0px 0px 10px;}
    0%{margin: 0px 0px  0px 0px;}
}
.alierheight{
    width: 80%;
}
p{
    text-align: justify;
}
.carousel-control-prev-icon{
    color: black;
    background-color: #073f87;
}
.carousel-control-next-icon{
    background-color: #073f87;
}
@media(max-width:992px){
    .gogoer{
        display: none;
    }
}
.apppp{
    position: relative;
    z-index: 999;
}
.image-with-shadow {
    filter: drop-shadow(5px 5px 10px #073f87);
  }
.colorofpng{
    margin-left: 10px;
    width: 28px;
    height: 24px;
}